import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, head } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { PageLinksWidget } from 'site-modules/shared/components/page-links-widget/page-links-widget';
import { VehicleModel } from 'client/data/models/vehicle-v2';
import { buildSubmodelsListPath, CoreResearchEntities, CoreResearchModel } from 'client/data/models/core-research';
import { bindToContent, CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { TrackingHandler } from 'client/tracking/handler';
import { TrackingMap } from 'client/tracking/maps/make/make';
import { getMakeHistoryContentPath, getMakeBreadcrumbs, filterSubmodels } from 'client/site-modules/make/utils/make';
import { TOP_MAKES_PATH } from 'site-modules/shared/constants/research';
import { Breadcrumbs } from 'site-modules/shared/components/breadcrumbs/breadcrumbs';
import { TopMakesSection } from 'site-modules/shared/components/top-makes-section/top-makes-section';
import { MrectNative } from 'site-modules/shared/components/native-ad/mrect/mrect-native';
import { ModelsView } from 'site-modules/make/components/models-view/models-view';
import { MakeHistory } from 'site-modules/make/components/make-history/make-history';
import { MakeNewsWidget } from 'site-modules/shared/components/news-widget/news-widget';
import { HeroImage } from 'site-modules/shared/components/hero-image/hero-image';

export class MakeUI extends Component {
  componentDidMount() {
    TrackingHandler.useMap(TrackingMap);
  }

  render() {
    const { make, makeSlug, topMakesContent, submodelsList, makeHeroImageContent } = this.props;
    const { pubStates = {}, name: makeName } = make;
    const breadcrumbsData = getMakeBreadcrumbs(makeName);

    const hasSubmodelsList = Boolean(submodelsList && submodelsList.length);

    const heroImage = head(makeHeroImageContent.links());
    const hasHeroImage = !!(heroImage && heroImage.href);

    return (
      <main id="main-content" className="make-page">
        {hasHeroImage && <HeroImage heroImage={heroImage} title={makeName} />}
        <Container>
          <Breadcrumbs data={breadcrumbsData} className="px-0 mb-2" />
          <Row>
            <Col xs={12} lg={8} className="pe-lg-1">
              {!hasHeroImage && <h1 className="heading-2">{makeName}</h1>}
              <MakeHistory makeSlug={makeSlug} makeName={makeName} hasCollapse={hasSubmodelsList} />
              {hasSubmodelsList && (
                <ModelsView
                  makeSlug={makeSlug}
                  makeName={makeName}
                  pubStates={pubStates}
                  submodelsList={submodelsList}
                />
              )}
              <div className="py-3_5 py-lg-0 hidden-lg-up hidden-sm-down">
                <MrectNative
                  position={'2'}
                  refreshable={false}
                  wiredBreakpoints={{ md: true }}
                  dfpCollapse
                  useMinHeight
                />
              </div>
            </Col>
            <Col xs={12} lg={4} className="ps-lg-1">
              <hr className="mt-0 mt-lg-1_5 mb-2 hidden-md-down" />
              <TopMakesSection
                topMakes={topMakesContent}
                className="px-1"
                linkClassName="size-16"
                linkTrackingId="make_index_view_makes"
              />
              <hr className="mt-0_5 mb-0 mb-lg-1_5" />
              <div className="py-3_5">
                <MrectNative position={'1'} refreshable={false} dfpCollapse useMinHeight />
              </div>
              <hr className="mt-0 mb-2 hidden-lg-up" />
              <div className="py-lg-3_5 hidden-md-down">
                <MrectNative
                  position={'2'}
                  refreshable={false}
                  wiredBreakpoints={{ lg: true, xl: true }}
                  wiredOnly
                  dfpCollapse
                  useMinHeight
                />
              </div>
            </Col>
          </Row>
          <MakeNewsWidget makeName={makeName} makeSlug={makeSlug} withDivider />
          <hr className="mt-1_5 mb-2 hidden-md-down" />
          <PageLinksWidget divideSections={false} title="Related information" />
        </Container>
      </main>
    );
  }
}

MakeUI.propTypes = {
  makeSlug: PropTypes.string.isRequired,
  make: PropTypes.shape({}),
  topMakesContent: CmsEntities.Content,
  submodelsList: PropTypes.arrayOf(CoreResearchEntities.VehicleSubmodel),
  makeHeroImageContent: CmsEntities.Content,
};

MakeUI.defaultProps = {
  make: {},
  topMakesContent: DEFAULT_CONTENT,
  submodelsList: null,
  makeHeroImageContent: DEFAULT_CONTENT,
};

const stateToPropsConfig = {
  make: bindToPath('makes', VehicleModel, (makes, { makeSlug }) => get(makes, makeSlug)),
  topMakesContent: bindToContent(TOP_MAKES_PATH, 'top-makes'),
  submodelsList: bindToPath(({ makeSlug }) => buildSubmodelsListPath(makeSlug), CoreResearchModel, filterSubmodels),
  makeHeroImageContent: bindToContent(({ makeSlug }) => getMakeHistoryContentPath(makeSlug), 'hero-content'),
};

export const Make = connectToModel(MakeUI, stateToPropsConfig);
